import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getRoles } from '@testing-library/react';
import { get } from 'react-hook-form';

const baseURl = process.env.REACT_APP_BASE_URL;

export const vowApi = createApi({
	reducerPath: 'vowApi',
	baseQuery: fetchBaseQuery({
		baseUrl: baseURl,
		prepareHeaders: (headers, { getState }) => {
			const { userToken } = getState().auth;
			if (userToken) {
				headers.set('authorization', `Bearer ${userToken}`);
			}

			return headers;
		},
	}),
	tagTypes: ['WorkerList', 'CompanyUser'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	endpoints: (builder) => ({
		getResult: builder.query({
			query: (name) => `living-wage/living-wage-result`,
		}),
		sendForm: builder.mutation({
			query: ({ id, ...body }) => ({
				url: `living-wage/living-wage/${id}`,
				method: 'POST',
				body: body,
			}),
		}),
		userLogin: builder.mutation({
			query: (data) => ({
				url: `login/login`,
				method: 'POST',
				body: data,
			}),
		}),
		addComplaint: builder.mutation({
			query: ({ id, ...data }) => ({
				url: `complaint/add-complaint/${id}`,
				method: 'POST',
				body: data,
			}),
		}),
		addOffer: builder.mutation({
			query: ({ id, ...data }) => ({
				url: `offers/add-offer/${id}`,
				method: 'POST',
				body: data,
			}),
		}),
		getComplaint: builder.query({
			query: () => `complaint/getAllComplaint`,
		}),
		getOffers: builder.query({
			query: () => `offers/getAllOffers`,
		}),
		getAnnounces: builder.query({
			query: () => `announcement/get-all-announcment`,
		}),
		getLivingWageSurveys: builder.query({
			query: () => `living-wage/getAll-living-wage`,
		}),
		getLivingWageSurveyResult: builder.query({
			query: (season) => `living-wage/get-living-wage-result/${season}`,
		}),
		getQrCode: builder.query({
			query: () => `qr-code/getQrCode`,
		}),
		createQrCode: builder.mutation({
			query: () => ({ url: `qr-code/createQrCode`, method: 'POST' }),
		}),
		getQrCodeById: builder.query({
			query: (id) => `qr-code/getqrcode/getQrCode/${id}`,
		}),
		setUpdateCompalaint: builder.mutation({
			query: (data) => ({
				url: `complaint/update-complaint`,
				method: 'POST',
				body: data,
			}),
		}),
		setAddAnnounce: builder.mutation({
			query: (data) => ({
				url: `announcement/add-announcement`,
				method: 'POST',
				body: data,
			}),
		}),
		updateaAnnounce: builder.mutation({
			query: (data) => ({
				url: `announcement/update-announcement`,
				method: 'POST',
				body: data,
			}),
		}),
		getWorkersList: builder.query({
			query: ({ limit, offset }) => `worker-list/getWorkersList?limit=${limit}&offset=${offset}`,
			providesTags: (result, error, arg) =>
				result
					? [...result.workersList.map(({ id }) => ({ type: 'WorkerList', id })), 'WorkerList']
					: ['WorkerList'],
		}),
		addWorker: builder.mutation({
			query: (data) => ({
				url: `worker-list/addWorkers`,
				method: 'POST',
				body: data,
			}),
		}),
		updateWorker: builder.mutation({
			query: (data) => ({
				url: `worker-list/updateWorker`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['WorkerList'],
		}),
		adminLogin: builder.mutation({
			query: (data) => ({
				url: `admin/admin-login`,
				method: 'POST',
				body: data,
			}),
		}),
		getAllCompany: builder.query({
			query: () => `company/get-all-company`,
		}),
		getCompanyUsers: builder.query({
			query: (company_id) => `user/admin/get-company-users/${company_id}`,
			providesTags: (result, error, arg) => {
				if (result && result.user) {
					return [
						...result.user.map(({ id }) => ({ type: 'CompanyUser', id })),
						{ type: 'CompanyUser', id: 'LIST' },
					];
				} else {
					return [{ type: 'CompanyUser', id: 'LIST' }];
				}
			},
		}),
		setCompany: builder.mutation({
			query: (data) => ({
				url: `company/company`,
				method: 'POST',
				body: data,
			}),
		}),
		addUser: builder.mutation({
			query: (data) => ({
				url: `user/register`,
				method: 'POST',
				body: data,
			}),
		}),
		getComplaintWithDate: builder.mutation({
			query: (data) => ({
				url: `complaint/get-complaints-with-date`,
				method: 'POST',
				body: data,
				responseHandler: async (response) =>
					window.location.assign(window.URL.createObjectURL(await response.blob())),
			}),
		}),
		createPassword: builder.mutation({
			query: (data) => ({
				url: `user/create-password`,
				method: 'POST',
				body: data,
			}),
		}),
		getDepartments: builder.query({
			query: (data) => ({
				url: `units/get-departments`,
				method: 'GET',
				params: data,
			}),
		}),
		getUnits: builder.query({
			query: (data) => ({
				url: `units/get-units`,
				method: 'GET',
				params: data,
			}),
		}),
		addDepartmentAndUnits: builder.mutation({
			query: (data) => ({
				url: `units/add-department-and-units`,
				method: 'POST',
				body: data,
			}),
		}),
		getLocations: builder.query({
			query: () => `locations/get-locations`,
		}),
		addLocation: builder.mutation({
			query: (data) => ({
				url: `locations/add-location`,
				method: 'POST',
				body: data,
			}),
		}),
		getAdminLocation: builder.query({
			query: (params) => ({
				url: `locations/get-admin-location`,
				method: 'GET',
				params: params,
			}),
		}),
		getAllSurveyForAdmin: builder.query({
			query: () => `survey/get-all-survey-for-admin`,
		}),
		insertCompanySurvey: builder.mutation({
			query: (data) => ({
				url: `survey/insert-company-survey`,
				method: 'POST',
			}),
		}),
		getTotalParticipants: builder.query({
			query: (params) => ({
				url: `survey/total-participants`,
				params: params,
			}),
		}),
		getAnswerAverages: builder.query({
			query: (params) => ({
				url: `survey/answer-averages`,
				params: params,
			}),
		}),
		getSurveyFormCompany: builder.query({
			query: () => ({
				url: `survey/get-survey-for-company`,
				method: 'GET',
			}),
		}),
		getSurveyQuestionReport: builder.query({
			query: (data) => ({
				url: `survey-reports/get-survey-question-report`,
				method: 'GET',
				params: data,
			}),
		}),
		getWorkerListForAdmin: builder.query({
			query: (data) => ({
				url: `worker-list/getWorkerListForAdmin`,
				method: 'GET',
				params: data,
			}),
		}),
		getRoles: builder.query({
			query: () => `roles`,
		}),
		setLeave: builder.mutation({
			query: (body) => ({
				url: `leaves/add-leave`,
				method: 'POST',
				body: body,
			}),
		}),
		getLeaveTypes: builder.query({
			query: () => `leaves/leave-types`,
		}),
		getLeaveList: builder.query({
			query: (companyId) => ({
				url: `leaves/leavelist/${companyId}`,
				method: 'GET',
			}),
		}),
		leaveByEmployeeId: builder.query({
			query: (employeeId) => ({
				url: `leaves/leave-by-employee/${employeeId}`,
				method: 'GET',
			}),
		}),
		getUserRights: builder.query({
			query: () => ({
				url: `user/user-rights`,
				method: 'GET',
			}),
		}),
		getCompanyLocation: builder.query({
			query: (companyId) => ({
				url: `company/location/${companyId}`,
				method: 'GET',
			}),
		}),
		updateCompanyUser: builder.mutation({
			query: (data) => ({
				url: `user/admin/update-company-users/${data.company_id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: (result, error, { id }) => [{ type: 'CompanyUser', id }],
		}),
		updateCompanyStatus: builder.mutation({
			query: (data) => ({
				url: `company/update-company-status/${data.company_id}`,
				method: 'PUT',
				body: data,
			}),
		}),
	}),
});

export const {
	useSendFormMutation,
	useUserLoginMutation,
	useGetComplaintQuery,
	useGetOffersQuery,
	useGetAnnouncesQuery,
	useGetLivingWageSurveysQuery,
	useGetLivingWageSurveyResultQuery,
	useGetQrCodeByIdQuery,
	useGetQrCodeQuery,
	useCreateQrCodeMutation,
	useAddComplaintMutation,
	useAddOfferMutation,
	useSetUpdateCompalaintMutation,
	useSetAddAnnounceMutation,
	useUpdateaAnnounceMutation,
	useGetWorkersListQuery,
	useAddWorkerMutation,
	useUpdateWorkerMutation,
	useAdminLoginMutation,
	useGetAllCompanyQuery,
	useGetCompanyUsersQuery,
	useSetCompanyMutation,
	useAddUserMutation,
	useGetComplaintWithDateMutation,
	useCreatePasswordMutation,
	useGetDepartmentsQuery,
	useLazyGetUnitsQuery,
	useAddDepartmentAndUnitsMutation,
	useGetLocationsQuery,
	useAddLocationMutation,
	useGetAdminLocationQuery,
	useGetAllSurveyForAdminQuery,
	useInsertCompanySurveyMutation,
	useGetTotalParticipantsQuery,
	useGetAnswerAveragesQuery,
	useGetSurveyFormCompanyQuery,
	useGetSurveyQuestionReportQuery,
	useGetUnitsQuery,
	useGetWorkerListForAdminQuery,
	useGetRolesQuery,
	useSetLeaveMutation,
	useGetLeaveTypesQuery,
	useGetLeaveListQuery,
	useLeaveByEmployeeIdQuery,
	useGetUserRightsQuery,
	useGetCompanyLocationQuery,
	useUpdateCompanyUserMutation,
	useUpdateCompanyStatusMutation,
} = vowApi;
