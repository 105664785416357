import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useAddUserMutation,
	useGetCompanyLocationQuery,
	useGetCompanyUsersQuery,
	useGetUserRightsQuery,
} from '../../../store/api';
import { Col, Tag, FloatButton, Table, Modal, Space, Input, Form, Select, message } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import UpdateCompanyUserModal from './updateModal';

const CompanyDetail = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { companyId } = useParams();
	const { data, refetch, isFetching } = useGetCompanyUsersQuery(companyId);
	const [addUser, { isSuccess, isError }] = useAddUserMutation();
	const { data: getUserRightsData, isLoading } = useGetUserRightsQuery();
	const { data: getLocations } = useGetCompanyLocationQuery(companyId, {
		skip: companyId === undefined,
	});

	const [form] = Form.useForm();
	const showModal = () => {
		setIsModalOpen(true);
	};

	useEffect(() => {
		console.log('companyId', companyId);
		console.log('getLocations', getLocations);
	}, []);

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const onFinish = async (values) => {
		const val = { ...values, company_id: companyId };
		console.log(val);
		const res = await addUser(val);
		console.log('res', res);
		if (res.data.isSuccess) {
			setIsModalOpen(false);
			refetch();
			message.success(res.data.message);
		}
		if (res.error) {
			message.error(res.error.data.message);
		}
		if (res.data) {
			message.error(res.data.message);
		}
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const columns = [
		{
			title: 'Kullanıcı ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Kullanıcı E-posta',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Kullanıcı Adı',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Lokasyon Adı',
			dataIndex: 'location_name',
			key: 'location_name',
		},
		{
			title: 'Kullanıcı Tipi',
			dataIndex: 'user_type',
			key: 'user_type',
			render: (text, record) => (
				<Tag color={record.user_type === 'ADMIN' ? 'success' : 'processing'}>
					{record.user_type}
				</Tag>
			),
		},
		{
			title: 'Düzenle',
			dataIndex: 'edit',
			key: 'edit',
			render: (text, record) => (
				<UpdateCompanyUserModal userType={getUserRightsData} user={record} update={refetch} />
			),
		},
	];

	return (
		<>
			{contextHolder}
			<FloatButton
				icon={<PlusCircleFilled />}
				type="primary"
				style={{ insetInlineEnd: 14 }}
				onClick={showModal}
			/>

			<Col xs={24} xl={16} style={{ marginTop: '20px' }}>
				<Table
					loading={isFetching}
					tableLayout="auto"
					dataSource={data?.user || []}
					columns={columns}
					rowKey={'id'}
				/>
			</Col>

			<Modal
				title="Yeni Kullanıc Ekle"
				open={isModalOpen}
				okButtonProps={{
					htmlType: 'submit',
					form: 'create-user-modal-form',
				}}
				onCancel={handleCancel}
			>
				<Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
					<Form
						id="create-user-modal-form"
						name="basic"
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						form={form}
					>
						<Form.Item
							name="user_name"
							rules={[
								{
									required: true,
									message: 'Lütfen kullanıcı e-posta giriniz!',
								},
							]}
						>
							<Input placeholder="Kullanıcı e-posta adresi" name="user_name" />
						</Form.Item>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: 'Lütfen kullanıcı adını giriniz!',
								},
							]}
						>
							<Input placeholder="Kullanıc Adı" name="name" />
						</Form.Item>
						<Form.Item name="user_type">
							<Select
								placeholder="Kullanıcı Tipi"
								loading={isLoading}
								options={getUserRightsData || []}
								fieldNames={{ label: 'name', value: 'name' }}
							/>
						</Form.Item>
						<Form.Item name="location_id">
							<Select
								placeholder="Lokasyon"
								loading={isLoading}
								options={getLocations || []}
								fieldNames={{ label: 'location_name', value: 'id' }}
							/>
						</Form.Item>
					</Form>
				</Space>
			</Modal>
		</>
	);
};

export default CompanyDetail;
