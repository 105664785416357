import { useState, useEffect } from 'react';
import {
	Col,
	Row,
	Card,
	FloatButton,
	Modal,
	Space,
	Input,
	Form,
	Table,
	Badge,
	Spin,
	message,
} from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import {
	useAddLocationMutation,
	useGetAdminLocationQuery,
	useGetAllCompanyQuery,
	useUpdateCompanyStatusMutation,
	useSetCompanyMutation,
} from '../../../store/api';
import { EditOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';

const columns = [
	{
		title: 'Lokaston Adı',
		dataIndex: 'location_name',
		key: 'location_name',
	},
];

const AdminHome = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [locationModal, setLocationModal] = useState();
	const [spinning, setSpinning] = useState(false);
	const [percent, setPercent] = useState(0);
	const { data, refetch, isFetching } = useGetAllCompanyQuery();
	const [addCompany] = useSetCompanyMutation();
	const [addLocation, { data: addLocationData, isLoading }] = useAddLocationMutation();
	const { data: getLocation } = useGetAdminLocationQuery(
		{ company_id: locationModal },
		{ skip: locationModal === undefined, refetchOnMountOrArgChange: true },
	);
	const [updateCompanyStatus] = useUpdateCompanyStatusMutation();
	let navigate = useNavigate();
	const [companyForm] = Form.useForm();
	const [locationForm] = Form.useForm();

	useEffect(() => {
		if (addLocationData) {
			messageApi.open({
				type: addLocationData.isSuccess ? 'success' : 'error',
				content: addLocationData.message,
			});
		}
	}, [addLocationData]);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const openLocationModal = (companyId) => {
		setLocationModal(companyId);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setLocationModal();
		locationForm.resetFields();
		companyForm.resetFields();
	};

	const onFinish = async (values) => {
		const res = await addCompany(values);
		if (res.data.isSuccess) {
			refetch();
			setIsModalOpen(false);
			companyForm.resetFields();
		}
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const addLocationSubmit = async (values) => {
		const res = await addLocation({ ...values, company_id: locationModal });
		if (res.data.isSuccess) {
			refetch();
			setLocationModal();
			locationForm.resetFields();
		}
	};

	const handleActive = async (value, status) => {
		console.log(value);
		await updateCompanyStatus({ status: status === 1 ? 0 : 1, company_id: value });
		refetch();
		setSpinning(true);
		let ptg = -10;
		const interval = setInterval(() => {
			ptg += 5;
			setPercent(ptg);

			if (ptg > 120) {
				clearInterval(interval);
				setSpinning(false);
				setPercent(0);
			}
		}, 100);
	};

	return (
		<Col lg={22}>
			<Spin spinning={spinning} percent={percent} fullscreen />
			<FloatButton
				icon={<PlusCircleFilled />}
				type="primary"
				style={{ insetInlineEnd: 14 }}
				onClick={showModal}
			/>
			<Row gutter={16} style={{ marginTop: 20, marginBottom: 20 }}>
				{data?.company.map((item, index) => {
					return (
						<Col
							lg={6}
							key={index}
							styles={{ marginBottom: 20 }}
							onClick={() => handleActive(item.id, item.isActive)}
						>
							<Badge.Ribbon
								text={item.isActive ? 'Aktif' : 'Pasif'}
								color={item.isActive ? 'green' : 'red'}
							>
								<Card
									style={{ marginTop: 16 }}
									title={item.company_name}
									bordered={false}
									hoverable
									actions={[
										<EditOutlined onClick={() => openLocationModal(item.id)} key="edit" />,
										<SettingOutlined
											key="setting"
											onClick={() => navigate(`/company-detail/${item.id}`, { replace: true })}
										/>,
										<UserOutlined
											key="setting"
											onClick={() => navigate(`/worker-list/${item.id}`, { replace: true })}
										/>,
									]}
								>
									<Card.Meta title={item.company_administrator} description={item.company_email} />
									<Card.Meta
										style={{ marginTop: 20 }}
										title={item.company_phone}
										description={item.create_datetime}
									/>
								</Card>
							</Badge.Ribbon>
						</Col>
					);
				})}
			</Row>
			<Modal
				title="Yeni Şirket Ekle"
				open={isModalOpen}
				okButtonProps={{
					htmlType: 'submit',
					form: 'create-company-modal-form',
				}}
				onCancel={handleCancel}
			>
				<Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
					<Form
						id="create-company-modal-form"
						name="basic"
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						form={companyForm}
					>
						<Form.Item
							name="company_name"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket Adı Giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Adı" name="company_name" />
						</Form.Item>
						<Form.Item
							name="company_admin"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket Yetkilisinin Adını Giriniz!',
								},
							]}
						>
							<Input placeholder="Yetkili Kişi" name="company_admin" />
						</Form.Item>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket eposta adresini giriniz!',
								},
							]}
						>
							<Input placeholder="Yetkili E-posta Adresi" name="email" />
						</Form.Item>
						<Form.Item
							name="phone"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket telefon numarasını giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Telefonu" name="phone" />
						</Form.Item>
						<Form.Item
							name="address"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket adresini giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Adresi" name="address" />
						</Form.Item>
						<Form.Item
							name="tax_admin"
							rules={[
								{
									required: false,
									message: 'Lütfen Şirket Vergi Dairesini giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Vergi Dairesi" name="tax_admin" />
						</Form.Item>
						<Form.Item
							name="tax_no"
							rules={[
								{
									required: false,
									message: 'Lütfen Şirket adresini giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Vergi Numarası" name="tax_no" />
						</Form.Item>
					</Form>
				</Space>
			</Modal>
			<Modal
				title="Lokasyon Ekle"
				open={locationModal !== undefined}
				okButtonProps={{
					htmlType: 'submit',
					form: 'add-company-location-modal-form',
				}}
				onCancel={handleCancel}
				confirmLoading={isLoading}
			>
				<Form
					id="add-company-location-modal-form"
					name="basic"
					layout="vertical"
					onFinish={addLocationSubmit}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					form={locationForm}
				>
					<Form.Item
						name="location_name"
						rules={[
							{
								required: true,
								message: 'Lütfen Lokasoyon Adı Giriniz!',
							},
						]}
					>
						<Input placeholder="Lokaston Adı" name="location_name" />
					</Form.Item>
				</Form>
				<Table pagination={false} dataSource={getLocation?.locations || []} columns={columns} />
			</Modal>
			{contextHolder}
		</Col>
	);
};
export default AdminHome;
