import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Form, Input, Button, Select, message } from 'antd';
import { useUpdateCompanyUserMutation, useGetCompanyLocationQuery } from '../../../store/api';

const UpdateCompanyUserModal = ({ user, update, userType }) => {
	const [form] = Form.useForm();
	const [messageApi, contextHolder] = message.useMessage();
	const { companyId } = useParams();
	const [updateCompanyUser, { isLoading }] = useUpdateCompanyUserMutation();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const { data: getLocations } = useGetCompanyLocationQuery(companyId, {
		skip: companyId === undefined,
	});
	useEffect(() => {
		if (user) {
			form.setFieldsValue(user);
		}
	}, [user, form]);

	const handleUpdate = async (values) => {
		console.log('values', values);
		try {
			await updateCompanyUser({ ...values, company_id: companyId }).unwrap();
			setIsModalVisible(false);
			messageApi.success('Güncelleme başarılı');
			update();
		} catch (error) {
			console.error('Failed to update user:', error);
			messageApi.success('Kullanıcı güncellenemedi');
		}
	};

	const handleOpen = (val) => {
		//setSelectedUser(user);
		setIsModalVisible(val);
	};

	const handleCloseModal = () => {
		setIsModalVisible(false);
		//setSelectedUser(null);
	};

	return (
		<>
			{contextHolder}
			<Button size="small" onClick={() => handleOpen(true)}>
				Düzenle
			</Button>
			<Modal
				open={isModalVisible}
				title="Update Company User"
				onCancel={() => setIsModalVisible(false)}
				okButtonProps={{
					htmlType: 'submit',
					form: 'create-user-update-form',
				}}
			>
				<Form id="create-user-update-form" form={form} layout="vertical" onFinish={handleUpdate}>
					<Form.Item hidden name="id">
						<Input />
					</Form.Item>
					<Form.Item
						name="name"
						label="Name"
						rules={[{ required: true, message: 'Please enter the name' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="user_name"
						label="Email"
						rules={[{ required: true, message: 'Please enter the email' }]}
					>
						<Input />
					</Form.Item>
					<Form.Item name="user_type">
						<Select
							placeholder="Kullanıcı Tipi"
							loading={isLoading}
							options={userType || []}
							fieldNames={{ label: 'name', value: 'name' }}
						/>
					</Form.Item>
					<Form.Item name="location_id" label="Location">
						<Select
							placeholder="Lokasyon"
							loading={isLoading}
							options={getLocations || []}
							fieldNames={{ label: 'location_name', value: 'id' }}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default UpdateCompanyUserModal;
